import { useState, useEffect, useRef } from 'react'
import { ethers } from "ethers"
import Identicon from 'identicon.js';
import { Row, Col, Card, Button, InputGroup, Form } from 'react-bootstrap'

export default function MyTokens({ contract }) {
  const audioRefs = useRef([]);
  const [isPlaying, setIsPlaying] = useState(null)
  const [loading, setLoading] = useState(true)
  const [priceAll, setPriceAll] = useState(null)
  const [myTokens, setMyTokens] = useState(null)
  const [selected, setSelected] = useState(0)
  const [previous, setPrevious] = useState(null)
  const [resellId, setResellId] = useState(null)
  const [resellPrice, setResellPrice] = useState(null)

  const loadMyTokens = async () => {
    // Get all unsold items/tokens
    const results = await contract.getMyTokens()
    console.log("results getMyTokens: ",results);
    const priceTemp = await contract.price()
  //  const priceResellTemp = await contract.getRoyaltyFeeResell()

    setPriceAll(priceTemp)
  //  setResellPrice(priceResellTemp)

    const myTokens = await Promise.all(results.map(async i => {
      console.log("i: ",i);
      // get uri url from contract
      const uri = await contract.tokenURI(i.tokenId)
      console.log("uri: ",uri);
      // use uri to fetch the nft metadata stored on ipfs
      const response = await fetch(uri + ".json")
      console.log("response: ",response);
      const metadata = await response.json()
      console.log("metadata: ",metadata);
    //  const identicon = `data:image/png;base64,${new Identicon(metadata.name + metadata.price, 330).toString()}`
    //  console.log("identicon: ",identicon);
      // define item object

      let item = {
    //    price: ethers.utils.parseEther(priceAll.toString()),
        price:i.tokenPrice,
        itemId: i.tokenId,
        name: metadata.name,
        image: metadata.image,
        resellPrice: null
      }
      console.log("item: ",item);
      return item
    }))
    setMyTokens(myTokens)
    console.log("myTokens.length: ",myTokens.length);
    console.log("myTokens: ",myTokens);
    setLoading(false)
  }
  const resellItem = async (item) => {
    console.log("resellItem:---->>> ");
    if (resellPrice === "0" || item.itemId !== resellId || !resellPrice) return
    // Get royalty fee
    console.log("2:---->>> ");

    const newprice = resellPrice.toString()
    console.log("newprice:---->>> ",newprice.toString());


    await (await contract.resellToken(item.itemId, newprice.toString())).wait()
    console.log("newprice:---->>> ",newprice.toString());
    loadMyTokens()
  }
  useEffect(() => {
    if (isPlaying) {
      audioRefs.current[selected].play()
      if (selected !== previous) audioRefs.current[previous].pause()
    } else if (isPlaying !== null) {
      audioRefs.current[selected].pause()
    }

  })
  useEffect(() => {
    !myTokens && loadMyTokens()
  })

  if (loading) return (
    <main style={{ color:'white',  padding: "1rem 0"}}>
      <h2>Loading...</h2>
    </main>
  )

  return (
    <div className="flex justify-center">
      {myTokens.length > 0 ?
        <div className="px-5 container">
          <Row xs={1} md={2} lg={3} className="g-4 py-5">
            {myTokens.map((item, idx) => (
              <Col key={idx} className="overflow-hidden">

                <Card style={{ width: 'auto', height:'auto', border: '2px solid white' }}>
                  <Card.Img variant="top" src={item.image} />
                  <Card.Body color="primary">
                    <Card.Title style={{ fontSize: 'small'}}>{item.name}</Card.Title>

                    <Card.Text className="mt-1">
                      {ethers.utils.formatUnits(item.price, 6)} USDT
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <InputGroup className="my-1">
                      <Button onClick={() => resellItem(item)} variant="outline-dark" size="lg">
                        Resell
                      </Button>

                      <Form.Control
                        onChange={(e) => {
                          setResellId(item.itemId)
                          setResellPrice(e.target.value)
                      //    setResellPrice(e.target.value)
                        }}
                        size="md"
                        value={resellId === item.itemId ? resellPrice : ''}
                        required type="number"
                        placeholder="Price in USDT"
                      />
                    </InputGroup>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        : (

          <main style={{ padding: "1rem 0", color:"white" }}>
            <h2>No owned tokens</h2>
          </main>
        )}
    </div>
  );
}
