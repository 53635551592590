import { useState, useEffect, useRef } from 'react'
import { ethers } from "ethers"
import Identicon from 'identicon.js';
import { Row, Col, Card, Button, InputGroup, Form } from 'react-bootstrap'

export default function MyTokens({ contract,ucontract,account }) {
  const audioRefs = useRef([]);
  const [isPlaying, setIsPlaying] = useState(null)
  const [loading, setLoading] = useState(true)
  const [priceAll, setPriceAll] = useState(null)
  const [myTokens, setMyTokens] = useState(null)
  const [selected, setSelected] = useState(0)
  const [previous, setPrevious] = useState(null)
  const [resellId, setResellId] = useState(null)
  const [resellPrice, setResellPrice] = useState(null)
  const [whitelisted, setWhitelisted] = useState(true)

  const loadMyTokens = async () => {
    // Get all unsold items/tokens
    const results = await contract.getAllUnsoldTokens()
    console.log("results getMyTokens: ",results);
    const priceTemp = await contract.price()
  //  const priceResellTemp = await contract.getRoyaltyFeeResell()

    setPriceAll(priceTemp)
  //  setResellPrice(priceResellTemp)
  const  wl = await contract.isWhitelisted(account)
  console.log("isWhitelisted: ",wl);
  setWhitelisted(wl)

    const myTokens = await Promise.all(results.map(async i => {
      console.log("i: ",i);
      // get uri url from contract
      const uri = await contract.tokenURI(i.tokenId)
      console.log("uri: ",uri);
      // use uri to fetch the nft metadata stored on ipfs
      const response = await fetch(uri + ".json")
      console.log("response: ",response);
      const metadata = await response.json()
      console.log("metadata: ",metadata);
    //  const identicon = `data:image/png;base64,${new Identicon(metadata.name + metadata.price, 330).toString()}`
    //  console.log("identicon: ",identicon);
      // define item object

      let item = {
    //    price: ethers.utils.parseEther(priceAll.toString()),
        price:i.tokenPrice,
        itemId: i.tokenId,
        name: metadata.name,
        image: metadata.image,
        resellPrice: null
      }
      console.log("item: ",item);
      return item
    }))
    setMyTokens(myTokens)
    console.log("myTokens.length: ",myTokens.length);
    console.log("myTokens: ",myTokens);
    setLoading(false)
  }

  const buyMarketItem = async (item) => {


    if(whitelisted){await (await contract.buyToken(item.itemId)).wait()}
    else {
      console.log("item: ->>>> ",item.price.toString())
      console.log("item: ->>>> ",item.itemId.toString())

      await ucontract.approve(contract.address,item.price.toString());
      await (await contract.buyToken(item.itemId.toString(), item.price)).wait()
      console.log("item: ->>>> ",item.itemId.toString())
    }
    loadMyTokens()
  }

  const resellItem = async (item) => {
    console.log("resellItem:---->>> ");
    if (resellPrice === "0" || item.itemId !== resellId || !resellPrice) return
    // Get royalty fee
    console.log("2:---->>> ");

    const newprice = ethers.utils.parseEther(resellPrice.toString())
    console.log("newprice:---->>> ",newprice.toString());
    const fee = await contract.getRoyaltyFeeResell(newprice)
    console.log("fee:---->>> ",fee.toString());
    await (await contract.resellToken(item.itemId, newprice.toString(),{ value: fee.toString() })).wait()
    console.log("newprice:---->>> ",newprice.toString());
    loadMyTokens()
  }

  useEffect(() => {
    !myTokens && loadMyTokens()
  })

  if (loading) return (
    <main style={{ color:'white',  padding: "1rem 0"}}>
      <h2>Loading...</h2>
    </main>
  )
  return (
    <div className="flex justify-center">
      {myTokens.length > 0 ?
        <div className="px-5 container">
          <Row xs={1} md={2} lg={4} className="g-4 py-3">
            {myTokens.map((item, idx) => (
              <Col key={idx} className="overflow-hidden">

                <Card style={{ width: 'auto', height:'auto', border: '2px solid white' }}>
                  <Card.Img variant="top" src={item.image} />
                  <Card.Body color="secondary">
                    <Card.Title style={{ fontSize: 'medium'}}>{item.name}</Card.Title>
                    <div className="d-grid px-4">
                    </div>
                    <Card.Text className="mt-1">
                      {ethers.utils.formatUnits(item.price, 6)} USDT
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                      <div className='d-grid my-1'>
                        <Button onClick={() => buyMarketItem(item)} variant="outline-dark" size="sm">
                          {`Buy NFT # ${item.itemId}`}
                        </Button>
                      </div>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        : (
          <main style={{ padding: "1rem 0", color:"white" }}>
            <h2>No owned tokens</h2>
          </main>
        )}
    </div>
  );
}
